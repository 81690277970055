import qs from "query-string";
import { FaXing } from "react-icons/fa";
import { Modal, Select, Spin, Typography } from "antd";
import { isEmpty, set } from "lodash";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import axios from "../lib/axios";
import { useGeneralState } from "../state/useGeneralState";
import { useCountdown } from "usehooks-ts";

const { Text } = Typography;

function MobileMoney({
  country,
  currency,
  reference,
  merchantId,
  customerEmail,
  customerName,
  amount,
  description,
  onPaymentSuccess,
}) {
  const [pageStep, setPageStep] = useState(1);
  const [selectedProvider, setSelectedProvider] = useState();
  const [account, setAccount] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const { monoUrl } = useGeneralState();
  const [visible, setVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Please give us sometime to confirm this transaction"
  );

  const correctedPhone1 = `${country.callingCodes[0]}${phoneNumber}`.replace(
    "+",
    ""
  );

  const correctedPhone2 = Number(correctedPhone1);

  const handleOk = () => {
    confirmPaymentAction(() => {});
  };

  const handleCancel = () => {
    if (window && window?.parent) {
      const query = qs.parse(window.location.search);
      window?.parent.postMessage({ name: "success" }, query?.origin);
    }
  };

  const validatePhone = useMutation(() => {
    return axios.get(
      `${monoUrl}v1/common/validate/${correctedPhone2}/${selectedProvider}?country=${country?.alpha2Code}&currency=${currency?.code}`
    );
  });

  const confirmPayment = useMutation(() => {
    return axios.post(`${monoUrl}v1/common/confirm-transaction`, {
      src_country_code: country?.alpha2Code,
      des_country_code: country?.alpha2Code,
      msisdn: correctedPhone2,
      amount: Number(amount) ?? amount,
      mno: selectedProvider,
      merchant_id: merchantId,
      reference,
      description: "Payment through Xcel sdk",
      isoCode: country?.alpha2Code,
    });
  });

  const makePayment = useMutation(() => {
    return axios.post(`${monoUrl}v1/common/collection`, {
      src_country_code: country?.alpha2Code,
      des_country_code: country?.alpha2Code,
      msisdn: correctedPhone2,
      amount: Number(amount) ?? amount,
      mno: selectedProvider,
      merchant_id: merchantId,
      reference,
      description: "Payment through Xcel sdk",
      isoCode: country?.alpha2Code,
    });
  });

  const { data: monos } = useQuery(["bank-list", country?.alpha2Code], () => {
    return axios.get(`${monoUrl}v1/common/mnos/${country?.alpha2Code}`);
  });

  const providers = (monos?.data?.data ?? [])?.map((i) => i).flat();

  const validatePhoneNumber = async () => {
    try {
      if (isEmpty(selectedProvider)) return;

      const result = await validatePhone.mutateAsync();
      const data = result?.data?.data;

      if (data?.status?.success) {
        setAccount(data);
        return;
      }

      toast.error(data?.message ?? "An error occured");
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "An error occured");
    }
  };

  const makePaymentAction = async () => {
    if (isEmpty(selectedProvider)) return;

    if (isEmpty(account)) {
      toast.error("Please enter a valid phone number");
      return;
    }

    try {
      await makePayment.mutateAsync();
      setPageStep(2);
    } catch (e) {
      toast.error(e?.response?.data?.message ?? "An error occured");
    }
  };

  const error2 = () => {
    setCountdown(false);
    stopCountdown();
    setErrorMessage(`Unfortunately, we are unable to confirm the status of this transaction at this time.
    Please be rest assured that we will notify you as soon as we can.`);
  };

  const error1 = () => {
    resetCountdown();
    startCountdown();

    setCountdown(true);
    setErrorMessage("Please give us sometime to confirm this transaction");
    // 291234567
    setVisible(true);
  };

  const confirmPaymentAction = async (error) => {
    if (isEmpty(selectedProvider)) return;

    try {
      const val = await confirmPayment.mutateAsync();

      if (!isEmpty(val.data?.data)) {
        if (val?.data?.data?.status === "success") {
          onPaymentSuccess();
        } else {
          error();
        }
      } else {
        error();
      }
    } catch (e) {
      error();
    }
  };

  const [countdown, setCountdown] = useState(false);

  const [count, { startCountdown, stopCountdown, resetCountdown }] =
    useCountdown({
      countStart: 60 * 1,
      intervalMs: 1000,
    });

  const minutes = Math.floor(count / 60);
  const seconds = count % 60;

  useEffect(() => {
    if (countdown && count === 0) {
      confirmPaymentAction(error2);
    }
  }, [count, countdown]);

  if (pageStep === 1) {
    return (
      <div className="payment-section m-auto mt-12 p-5">
        <h3>Pay with Mobile Money</h3>
        <p className="text-gray-400">
          Enter your details below to complete this transaction
        </p>
        <div className="my-7">
          <div className="flex flex-col gap-3">
            <Text className="font-bold text-[#031339]">Select Provider</Text>

            <Select
              onSelect={(e) => {
                setSelectedProvider(e);
              }}
              defaultValue={selectedProvider}
              className="select-before"
              placeholder="Select Provider"
            >
              {providers.map((i) => {
                return <Select.Option value={i}>{i}</Select.Option>;
              })}
            </Select>
          </div>
        </div>
        <div className="my-7">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            for="username"
          >
            Mobile Number
          </label>
          <div className="flex flex-wrap -mx-3 mb-2">
            <div class="w-1/4 px-3 mb-6 md:mb-0">
              <select
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="banks"
                id="banks"
              >
                <option value={country.callingCodes[0]}>
                  +{country.callingCodes[0]}
                </option>
              </select>
            </div>
            <div class="w-3/4 px-3 mb-6 md:mb-0">
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="usernamexx"
                type="text"
                placeholder="Phone Number"
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                  setAccount(undefined);
                }}
              />
            </div>
          </div>

          {account && (
            <p className="text-[#0095FF] text-xs italic float-right flex pt-2 mb-3 -top-2 items-center">
              <span>
                {(account?.first_name ?? "") + " " + (account?.last_name ?? "")}{" "}
                {isEmpty(account?.first_name ?? "") && correctedPhone2}
              </span>
              <span className="ml-2 text-center mt-[1.2px]">
                <img src="./images/green-check.svg" alt="" />
              </span>
            </p>
          )}
        </div>

        <div className="my-8 flex flex-col gap-4 w-full">
          <button
            class="bg-[#0095FF] hover:bg-[#0095FFb6] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
            type="button"
            onClick={async () => {
              if (isEmpty(account)) {
                validatePhoneNumber();
              } else {
                makePaymentAction();
              }
            }}
          >
            Proceed{" "}
            <span>
              {(makePayment.isLoading || validatePhone.isLoading) && <Spin />}
            </span>
          </button>
        </div>
      </div>
    );
  } else if (pageStep === 2) {
    return (
      <div className="payment-section m-auto mt-12 p-5">
        <h3>Pay with Mobile Money</h3>
        <p className="text-gray-400">
          Complete this transaction using your mobile device and click on the
          button below to confirm
        </p>

        <div className="my-10">
          <button
            class="bg-[#0095FF] hover:bg-[#0095FFb6] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
            type="button"
            onClick={() => confirmPaymentAction(error1)}
          >
            I have made this payment{" "}
            <span>{confirmPayment.isLoading && <Spin />}</span>
          </button>
        </div>

        <Modal
          title="Confirming Transaction"
          visible={visible}
          onOk={handleOk}
          closeIcon={
            <div
              className="flex justify-center items-center"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setVisible(false);
                setCountdown(false);
                stopCountdown();
                setErrorMessage(
                  "Please give us sometime to confirm this transaction"
                );
              }}
            >
              <span className="text-black text-md font-light">x</span>
            </div>
          }
          okText="Check Again"
          cancelText="Close Payment And Wait For Feedback"
          okButtonProps={{ style: { backgroundColor: "#0095FF" } }}
          onCancel={handleCancel}
        >
          <div className="flex flex-row gap-2">
            {countdown && count > 0 && <Spin />}

            <p>{errorMessage}</p>

            {countdown && (
              <span>
                {count < 60
                  ? `${seconds}s left`
                  : `${minutes}:${seconds < 10 ? "0" : ""}${seconds} mins`}
              </span>
            )}
          </div>
        </Modal>
      </div>
    );
  } else {
    return null;
  }
}
export default MobileMoney;
