import { combine } from "zustand/middleware";
import { persist } from ".";
import { PaymentMethod } from "../components/PaymentMethods";
import create from "zustand";

const defaultState = {
  url: "",
  transactionBaseUrl: "",
  monoUrl: "",
  env: "test",
  readOnly: false,
  method: PaymentMethod.PayWithXCEL,
};

export const useGeneralState = create(
  persist(
    {
      key: "general",
    },
    combine(defaultState, (set) => ({
      setMethod: (method) =>
        set((state) => ({
          ...state,
          method,
        })),
      setReadOnly: (readOnly) =>
        set((state) => ({
          ...state,
          readOnly,
        })),
      setUrl: (url) =>
        set((state) => ({
          ...state,
          url,
        })),
      setMonoUrl: (monoUrl) =>
        set((state) => ({
          ...state,
          monoUrl,
        })),
      setTransactionBaseUrl: (transactionBaseUrl) =>
        set((state) => ({
          ...state,
          transactionBaseUrl,
        })),
      setEnv: (env) =>
        set((state) => ({
          ...state,
          env,
        })),
      reset: () => set(() => defaultState),
    }))
  )
);
