import { useState } from "react";
import { useGeneralState } from "../state/useGeneralState";
import { Select } from "antd";

export const PaymentMethod = {
  PayWithXCEL: "Pay with XCEL",
  PayWithCard: "Pay with Card",
  PayWithBankApp: "Pay with Bank App",
  PayWithBankAccount: "Pay with Bank Account",
  PayWithMobileMoney: "Pay with Mobile Money",
  PayWithUSSD: "Pay with USSD",
  PayWithQRCode: "Pay with QR Code",
};

export const PaymentMethodDescriptior = {};
PaymentMethodDescriptior[PaymentMethod.PayWithXCEL] = "Xcel";
PaymentMethodDescriptior[PaymentMethod.PayWithCard] = "Card";
PaymentMethodDescriptior[PaymentMethod.PayWithBankAccount] = "Bank";
PaymentMethodDescriptior[PaymentMethod.PayWithBankApp] = "Bank App";
PaymentMethodDescriptior[PaymentMethod.PayWithMobileMoney] = "Mobile Money";
PaymentMethodDescriptior[PaymentMethod.PayWithQRCode] = "QR-Code";

function PaymentMethods(props) {
  const { method } = useGeneralState();

  const onMethodSelected = (item) => {
    props.onMethodSelected(item.key);
  };

  const data = [
    {
      key: PaymentMethod.PayWithXCEL,
      title: "XCEL",
      icon: "./images/option-logo.svg",
    },
    {
      key: PaymentMethod.PayWithCard,
      title: "Card",
      icon: "./images/card.svg",
    },
    {
      key: PaymentMethod.PayWithBankApp,
      title: "Bank App",
      icon: "./images/bank-app.svg",
    },
    {
      key: PaymentMethod.PayWithBankAccount,
      title: "Bank Account",
      icon: "./images/bank-account.svg",
    },
    {
      key: PaymentMethod.PayWithMobileMoney,
      title: "Mobile Money",
      icon: "./images/momo.svg",
    },
    {
      key: PaymentMethod.PayWithUSSD,
      title: "USSD Code",
      icon: "./images/ussd.svg",
    },
    {
      key: PaymentMethod.PayWithQRCode,
      title: "Pay with QR Code",
      icon: "./images/qr-code.svg",
    },
  ];

  const newData = data.filter((i) => {
    return (props?.selectedPaymentMethod ?? []).includes(i.key);
  });

  const [hover, setHover] = useState();

  return (
    <>
      <ul className="flex flex-row md:flex-col flex-wrap w-auto md:w-[220px]">
        {newData.map((item) => {
          const active = item.key === method;
          const hoverId = hover === item.key;

          return (
            <li
              className={`method-card ${active && "active"} justify-between`}
              key={item.key}
              onMouseEnter={(e) => {
                setHover(item.key);
              }}
              onMouseLeave={() => {
                setHover(undefined);
              }}
              onClick={() => {
                onMethodSelected(item);
              }}
            >
              <div className="flex flex-row gap-2 justify-center items-center">
                <img
                  src={`${item.icon}`}
                  alt=""
                  srcSet=""
                  className={`rounded-full w-[40px] h-[40px] p-1 ${
                    active ? "bg-white" : "bg-[#eee]"
                  }`}
                />
                <div className="hidden md:flex">{item.title}</div>
              </div>
            </li>
          );
        })}
      </ul>

      {/* <Select style={{ width: 70 }}>
        <Select.Option value="44">
          <li className={`method-card active justify-between`}>
            <div className="flex flex-row gap-2 justify-center items-center">
              <img
                src={`./images/etranzact.png`}
                alt=""
                srcSet=""
                className={`rounded-full w-[40px] h-[40px] p-1 bg-white`}
              />
              <div className="hidden md:flex">Xcel</div>
            </div>
          </li>
        </Select.Option>
        <Select.Option value="234">+234</Select.Option>
      </Select> */}
    </>
  );
}

export default PaymentMethods;
