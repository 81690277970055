function QrCode() {
    return (
        <div className="payment-section m-auto mt-12 p-5">
            <h3>Pay with QR Code</h3>
            <p className="text-gray-400">
                Please scan the QR code to make payment
            </p>
            <div className="my-7 flex justify-center text-center">
                <img src="./images/qr-code-big.svg" alt="qr code" />
            </div>
        </div>
    );
}
export default QrCode;
