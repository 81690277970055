import qs from "query-string";
import { Modal, Spin } from "antd";
import { isEmpty, result, set } from "lodash";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useCountdown } from "usehooks-ts";
import { default as axios, default as axiosInstance } from "../lib/axios";
import { useGeneralState } from "../state/useGeneralState";
import PlainCard from "./PlainCard";

const GhanaCard = ({
  keys,
  stripeId,
  amount,
  currency,
  country,
  reference,
  customerEmail,
  query,
  customerName,
  onPaymentSuccess,
  description,
  setUser,
  merchantId,
  merchantName,
  user,
}) => {
  const { transactionBaseUrl } = useGeneralState();
  const [session_id, setSessionId] = useState();

  const [_3DHtml, set3dHtml] = useState();
  const [is3DOpen, setIs3DOpen] = useState(false);
  const [iframeKey, setIframeKey] = useState(0);
  const [global, setGlobal] = useState();

  const [errorMessage, setErrorMessage] = useState(
    "Please give us sometime to confirm this transaction"
  );

  const redirectUrl = "https://checkout.xcelapp.com?redirect=true";

  const getSessionId = useMutation(async () => {
    const result = await axiosInstance.post(
      `${transactionBaseUrl}mastercard/create-hosted-session`,
      {
        redirectUrl,
        merchantName,
        merchant_id: merchantId,
        reference: reference,
        customerName,
        amount: Number(amount),
        alpha2CountryCode: country.alpha2Code,
        description: isEmpty(description) ? "Payment" : description,
      }
    );
    return result.data.session;
  });

  const initiateAuthentication = useMutation(() =>
    axiosInstance.post(
      `${transactionBaseUrl}mastercard/initiate-authentication`,
      {
        reference,
        session_id,
        alpha2CountryCode: country.alpha2Code,
        customerName,
        merchant_id: merchantId,
        amount: Number(amount),
        description: isEmpty(description) ? "Payment" : description,
      }
    )
  );

  const authenticatePayer = useMutation(() =>
    axiosInstance.post(`${transactionBaseUrl}mastercard/authenticate-payer`, {
      redirectUrl,
      reference,
      session_id,
      alpha2CountryCode: country.alpha2Code,
      // ipAddress: "127.0.0.1",
      amount: Number(amount),
    })
  );

  const makePaymentMutate = useMutation(() =>
    axiosInstance.post(`${transactionBaseUrl}mastercard/make-payment`, {
      reference,
      session_id,
      alpha2CountryCode: country.alpha2Code,
      amount: Number(amount),
    })
  );

  const makePayment = async () => {
    set3dHtml(`<div class="flex flex-col justify-center items-center"/>`);
    try {
      await initiateAuthentication.mutateAsync();
    } catch {}

    try {
      const result2 = await authenticatePayer.mutateAsync();
      setIs3DOpen(true);
      setIframeKey((prevKey) => prevKey + 1);
      set3dHtml(result2.data.authentication?.redirect?.html);
    } catch {
      toast.error("Something went wrong. Please reload and try again!");
    }
  };

  const makePaymentAfter3D = async () => {
    try {
      const result3 = await makePaymentMutate.mutateAsync();
      setIs3DOpen(false);

      if (result3?.data?.result === "SUCCESS") {
        // confirmPaymentAction(error1); TODO: Come back to this
        onPaymentSuccess();
      } else {
        toast.error("Payment failed. Please try again!");
      }
    } catch (e) {
      toast.error("Payment failed. Please try again!");
    }
  };

  const [visible, setVisible] = useState(false);
  const { readOnly } = useGeneralState();

  const onInit = async () => {
    const sessionId = await getSessionId.mutateAsync();
    setSessionId(sessionId?.id);

    window.PaymentSession.configure({
      session: sessionId?.id,
      fields: {
        card: {
          number: "#card-number",
          securityCode: "#cvc",
          expiryMonth: "#expiry-month",
          expiryYear: "#expiry-year",
          nameOnCard: "#nameOnCard",
        },
      },
      frameEmbeddingMitigation: ["javascript"],
      callbacks: {
        initialized: function (response) {
          if (response.status === "system_error") {
            toast.error(response.message + "\n Please reload and try again!");
          }
        },
        formSessionUpdate: function (response) {
          if (response.status) {
            console.log(JSON.stringify(response), " === response");
            if ("ok" == response.status) {
              console.log("Session updated with data: " + response.session.id);

              //check if the security code was provided by the user
              if (response.sourceOfFunds.provided.card.securityCode) {
                console.log("Security code was provided.");
              }

              //check if the user entered a Mastercard credit card
              if (response.sourceOfFunds.provided.card.scheme == "MASTERCARD") {
                console.log("The user entered a Mastercard credit card.");
              }

              // confirmPaymentAction(error1);

              makePayment();
            } else if ("fields_in_error" == response.status) {
              console.log("Session update failed with field errors.");
              if (response.errors.cardNumber) {
                toast.error("Card number invalid or missing.");
              }
              if (response.errors.expiryYear) {
                toast.error("Expiry year invalid or missing.");
              }
              if (response.errors.expiryMonth) {
                toast.error("Expiry month invalid or missing.");
              }
              if (response.errors.securityCode) {
                toast.error("Security code invalid.");
              }
            } else if ("request_timeout" == response.status) {
              toast.error(
                "Session update failed with request timeout: " +
                  response.errors.message
              );
            } else if ("system_error" == response.status) {
              toast.error(
                "Session update failed with system error: " +
                  response.errors.message
              );
            }
          } else {
            toast.error("Session update failed: " + response);
          }
        },
      },
      interaction: {
        displayControl: {
          formatCard: "EMBOSSED",
          invalidFieldCharacters: "REJECT",
        },
      },
    });

    window.PaymentSession.onValidityChange(
      ["card.number", "card.nameOnCard"],
      function (selector, result) {
        if (result.isValid) {
          console.log("The field value is valid");

          document.querySelector(selector).style.borderColor = "green";
        } else if (result.isIncomplete) {
          console.log("The field value is not yet valid");
          document.querySelector(selector).style.borderColor = "grey";
        } else {
          console.log("The field value is invalid");

          document.querySelector(selector).style.borderColor = "red";
        }
      }
    );
  };

  useEffect(() => {
    if (reference) {
      onInit();
    }
  }, [reference]);

  const confirmPayment = useMutation(() => {
    return axios.get(
      `${transactionBaseUrl}mastercard/confirm-transaction?reference=${reference}&alpha2CountryCode=${country?.alpha2Code}`
    );
  });

  const handleOk = () => {
    confirmPaymentAction(() => {});
  };

  const error2 = () => {
    setCountdown(false);
    stopCountdown();
    setErrorMessage(`Unfortunately, we are unable to confirm the status of this transaction at this time.
    Please be rest assured that we will notify you as soon as we can.`);
  };

  const error1 = () => {
    resetCountdown();
    startCountdown();

    setCountdown(true);
    setErrorMessage("Please give us sometime to confirm this transaction");
    // 291234567
    setVisible(true);
  };

  const handleCancel = () => {
    if (window && window?.parent) {
      const query = qs.parse(window.location.search);
      window?.parent.postMessage({ name: "success" }, query?.origin);
    }
  };

  const confirmPaymentAction = async (error) => {
    try {
      const val = await confirmPayment.mutateAsync();

      if (!isEmpty(val.data?.data)) {
        if (val?.data?.data?.status === "success") {
          onPaymentSuccess();
        } else {
          error();
        }
      } else {
        error();
      }
    } catch (e) {
      error();
    }
  };

  const [countdown, setCountdown] = useState(false);

  const [count, { startCountdown, stopCountdown, resetCountdown }] =
    useCountdown({
      countStart: 60 * 1,
      intervalMs: 1000,
    });

  const minutes = Math.floor(count / 60);
  const seconds = count % 60;

  useEffect(() => {
    if (countdown && count === 0) {
      confirmPaymentAction(error2);
    }
  }, [count, countdown]);

  return (
    <div className="mb-20">
      <PlainCard
        {...{
          onPaymentSuccess,
          country,
          stripeId,
          setNameGlobal: (e) => {},
          handleSubmitProps: async ({
            number: cardNumber,
            cvc,
            exp_month,
            exp_year,
            stripeId,
          }) => {
            try {
              window.PaymentSession.updateSessionFromForm("card");
            } catch (e) {
              console.log(e);
              toast.error("Something went wrong");
            }
          },
          readOnly,
        }}
      />

      <Modal
        title="Confirming Transaction"
        visible={is3DOpen}
        onOk={makePaymentAfter3D}
        closeIcon={
          <div
            className="flex justify-center items-center"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <span className="text-black text-md font-light">x</span>
          </div>
        }
        okText="Make Payment"
        cancelText="Close"
        okButtonProps={{
          style: {
            backgroundColor: "#0095FF",
            cursor: "pointer",
            marginTop: 10,
          },
        }}
        onCancel={() => {
          setIs3DOpen(false);
        }}
      >
        <iframe
          srcDoc={_3DHtml}
          className="w-full"
          key={iframeKey}
          style={{ height: 500 }}
        />
      </Modal>

      <Modal
        title="Confirming Transaction"
        visible={visible}
        onOk={handleOk}
        closeIcon={
          <div
            className="flex justify-center items-center"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setVisible(false);
              setCountdown(false);
              stopCountdown();
              setErrorMessage(
                "Please give us sometime to confirm this transaction"
              );
            }}
          >
            <span className="text-black text-md font-light">x</span>
          </div>
        }
        okText="Check Again"
        cancelText="Close Payment And Wait For Feedback"
        okButtonProps={{ style: { backgroundColor: "#0095FF" } }}
        onCancel={handleCancel}
      >
        <div className="flex flex-row gap-2">
          {countdown && count > 0 && <Spin />}

          <p>{errorMessage}</p>

          {countdown && (
            <span>
              {count < 60
                ? `${seconds}s left`
                : `${minutes}:${seconds < 10 ? "0" : ""}${seconds} mins`}
            </span>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default GhanaCard;
