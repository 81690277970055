import qs from "query-string";
import moment from "moment";
import React from "react";
import { useGeneralState } from "../state/useGeneralState";
import { PaymentMethod, PaymentMethodDescriptior } from "./PaymentMethods";
import { isEmpty } from "lodash";

function Success({
  currency,
  merchant,
  user,
  customerName,
  customerEmail,
  amount,
  handleCloseModal,
  reference,
}) {
  const { method } = useGeneralState();

  return (
    <div className="container">
      <div className="receipt-modal">
        <div className="receipt-modal-top">
          <div className="items-center flex justify-center flex-col gap-2">
            <img src="./images/ep_success-filled.svg" alt="" />
            <h2>Congratulations</h2>
            <p>
              Your payment of{" "}
              <span className="font-bold">
                {currency?.symbol}
                {amount}
              </span>{" "}
              was successful
            </p>
          </div>
        </div>
        <div className="main">
          <table>
            <tr className="px-3">
              <td>Transaction Type</td>
              <td>
                {
                  PaymentMethodDescriptior[
                    `${method ?? PaymentMethod.PayWithXCEL}`
                  ]
                }
              </td>
            </tr>
            <tr className="px-3">
              <td>Transaction Reference</td>
              <td>{reference}</td>
            </tr>
            <tr className="px-3">
              <td>Name</td>
              <td>
                {isEmpty(customerName) ? (
                  <>
                    {user?.first_name} {user?.last_name ?? ""}
                  </>
                ) : (
                  <>{customerName}</>
                )}
              </td>
            </tr>

            <tr className="px-3">
              <td>Merchant</td>
              <td>{merchant?.reg_name}</td>
            </tr>
            {/* {!isCard && (
              <tr className="px-3">
                <td>Account No.</td>
                <td>
                  {method === "xcel"
                    ? merchant?.account?.accountNumber
                    : merchant?.bank?.account_no}
                </td>
              </tr>
            )} */}
            <tr className="px-3">
              <td>Amount</td>
              <td>
                {currency?.symbol}
                {amount}
              </td>
            </tr>

            <tr className="px-3">
              <td>Status</td>
              <td>Successful</td>
            </tr>
            <tr className="px-3">
              <td>Date</td>
              <td>{moment().format("YYYY-MM-DD")}</td>
            </tr>

            <tr className="px-3">
              <td>Time</td>
              <td>{moment().format("hh:mm A")}</td>
            </tr>
          </table>
        </div>
        <div>
          <div className="footer">
            <button
              className="bg-[#fff] hover:bg-[#fff] border-[#979797] border-[1px] text-[#979797] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
              type="button"
              onClick={() => {
                if (window && window?.parent) {
                  const query = qs.parse(window.location.search);
                  window?.parent.postMessage(
                    { name: "success" },
                    query?.origin
                  );
                }
              }}
            >
              {" "}
              Close
            </button>

            <button
              className="bg-[#0095FF] hover:bg-[#0095FFb6] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
              type="button"
              onClick={() => {
                window.print();
              }}
            >
              {" "}
              Download Receipt
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Success;
