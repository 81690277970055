import React from "react";

const PaymentSummary = ({ total, currency, vat, merchant }) => {
  const formatAmount = (amount) => {
    return `${currency?.symbol} ${amount}`;
  };

  const item = [
    { title: "Pay", value: merchant },
    { title: "Amount", value: formatAmount(total) },
    { title: "VAT", value: formatAmount(vat) },
  ];

  return (
    <div className="w-full bg-white">
      <div className="bg-[#A4A4A4] w-full h-[50px] text-white justify-start px-4 flex items-center font-bold">
        Payment Summary
      </div>

      <div className="flex flex-col gap-2 px-3 py-10">
        {item.map((i) => {
          return (
            <div className="flex flex-row justify-between items-center text-xs">
              <span className="text-[#777777]">{i.title}</span>
              <span className="text-[#031339]">{i.value}</span>
            </div>
          );
        })}
      </div>

      <div className="w-full h-[1px] bg-[#DADADA]" />

      <div className="flex flex-col gap-2 px-3 py-3">
        <div className="flex flex-row justify-between items-center text-xs">
          <span className="text-[#777777]">Total</span>
          <span className="text-[#031339] font-bold text-sm">
            {formatAmount(total)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PaymentSummary;
