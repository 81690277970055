import { Spin } from "antd";
import lodash from "lodash";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useCountdown } from "usehooks-ts";
import axios from "../lib/axios";
import { getCountryByCode } from "../lib/countries";
import { useGeneralState } from "../state/useGeneralState";
import { toast } from "react-toastify";

function BankAppUk({
  method,
  merchant,
  onPaymentSuccess,
  onUserVerified,
  amount,
  currency,
  setMethod,
  country,
  pageStep,
}) {
  const [madePayment, setMadePayment] = useState(false);
  const [lastFour, setLastFour] = useState("");
  const [loading] = useState(false);
  const [countdown, setCountdown] = useState(false);
  const isGhana = country?.alpha2Code === "GH";

  const [count, { startCountdown, stopCountdown, resetCountdown }] =
    useCountdown({
      countStart: 60 * 30,
      intervalMs: 1000,
    });

  useEffect(() => {
    if (count <= 0) {
      toast.error(
        "Payment verification failed. Support team would manually verify your payment."
      );
    }
  }, [count]);

  const { transactionBaseUrl } = useGeneralState();

  const { data: merchantBank } = useQuery(
    ["merchant-bank", merchant, country?.alpha2Code],
    () => {
      return axios.post(`${transactionBaseUrl}transactions/payment-account`, {
        merchant_id: merchant?.merchant_id,
        currency_code: currency?.code,
      });
    },
    {
      enabled: !!country?.alpha2Code && !!merchant?.merchant_id,
      retry: true,
    }
  );

  const account = merchantBank?.data?.data;

  const validateBankPayment = async () => {
    setCountdown(true);
    startCountdown();
  };

  const minutes = Math.floor(count / 60);
  const seconds = count % 60;

  const checkValidatePayment = async () => {
    try {
      const country = getCountryByCode(merchant.country_code);
      await axios.get(
        `${transactionBaseUrl}transactions/confirm-transaction?src_amount_paid=${amount}&src_account=${lastFour}&des_account=${
          account?.account_number
        }&isoCode=${country?.alpha2Code}&date=${new Date().toISOString()}`
      );
      onPaymentSuccess();
    } catch (e) {
      toast.error("We could not verify your payment. Try again later.");
      stopCountdown();
      setCountdown(false);
    }
  };

  useEffect(() => {
    if (countdown && count > 0) {
      checkValidatePayment();
    }
  }, [Math.floor(count / 60), countdown]);

  return (
    <>
      <div className="payment-section m-auto mt-12 p-5">
        <h3>Pay with Bank App</h3>
        <p className="text-gray-400">
          Make payment to the account details below to complete your transaction
          and click on the button to confirm
        </p>
        <div
          className={`my-7 py-4 ${madePayment ? "" : "bg-gray-100"} rounded-md`}
        >
          {madePayment ? (
            <>
              <div className="flex flex-col gap-2">
                <div className="flex gap-2 text-center items-center  font-bold text-xs pl-3">
                  Enter the 8-digit bank account you made payment from
                </div>
                <div class="w-full px-3 mb-6 md:mb-0">
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="accountnumber"
                    type="text"
                    placeholder="Bank account"
                    onChange={(e) => {
                      setLastFour(e.target.value);
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <table>
              <tr>
                <td className="flex gap-2 text-center items-center">
                  <span>
                    <img src="./images/account-circle.svg" alt="" />
                  </span>
                  Acct. Name
                </td>
                <td>{account?.merchant_name ?? ""}</td>
              </tr>
              <tr>
                {isGhana ? (
                  <>
                    <td className="flex gap-2 text-center items-center">
                      <span>
                        <img src="./images/bank-account.svg" alt="" />
                      </span>
                      Card Number
                    </td>
                    <td>{account?.card_number ?? " "}</td>
                  </>
                ) : (
                  <>
                    <td className="flex gap-2 text-center items-center">
                      <span>
                        <img src="./images/bank-account.svg" alt="" />
                      </span>
                      Sort Code
                    </td>
                    <td>{account?.sort_code ?? " "}</td>
                  </>
                )}
              </tr>

              {!isGhana && (
                <tr>
                  <td className="flex gap-2 text-center items-center">
                    <span>
                      <img src="./images/dial-pad.svg" alt="" />
                    </span>
                    Acct. No
                  </td>
                  <td>{account?.account_number ?? ""} </td>
                </tr>
              )}
            </table>
          )}
        </div>
        <div className="my-8">
          <button
            class="bg-[#0095FF] hover:bg-[#0095FFb6] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
            type="button"
            loading={loading}
            disabled={
              loading ||
              lodash.isEmpty(account) ||
              countdown ||
              (madePayment && lodash.isEmpty(lastFour))
            }
            onClick={() => {
              if (!madePayment) {
                setMadePayment(true);
              } else {
                validateBankPayment();
              }
            }}
          >
            <div className="flex justify-center items-center gap-2">
              {countdown && <Spin />}
              <span>
                {madePayment
                  ? "Complete Transactions"
                  : "I have made this payment"}
              </span>

              {countdown && (
                <span>
                  {count < 60
                    ? `${seconds}s left`
                    : `${minutes}:${seconds < 10 ? "0" : ""}${seconds} mins`}
                </span>
              )}
            </div>
          </button>
        </div>
      </div>
    </>
  );
}

export default BankAppUk;
