/** @format */

import { isEmpty } from 'lodash';
import qs from 'query-string';
import { React, useEffect, useState } from 'react';
import { BsX } from 'react-icons/bs';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CSSTransition } from 'react-transition-group';
import { v4 as uuid } from 'uuid';
import './App.css';
import BankApp from './components/BankApp';
import BankTransfer from './components/BankTransfer';
import MobileMoney from './components/MobileMoney';
import PaymentMethods, { PaymentMethod } from './components/PaymentMethods';
import PaymentSummary from './components/PaymentSummary';
import QrCode from './components/QrCode';
import StripeCardPayment from './components/StripeCardPayment';
import Success from './components/Success';
import USSD from './components/USSD';
import XcelPay from './components/XcelPay';
import axios, { setAuthorisationToken } from './lib/axios';
import { getCountryByCode } from './lib/countries';
import { useGeneralState } from './state/useGeneralState';

const queryClient = new QueryClient();

function App() {
	const [step, setStep] = useState(3);
	const [products, setProducts] = useState([]);
	const [country, setCountry] = useState();
	const [currency, setCurrency] = useState();
	const [merchantDetails, setMerchantDetails] = useState();
	const [isReady, setIsReady] = useState(false);
	const [merchant, setMerchant] = useState();
	const [stripeId, setStripeId] = useState();
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [user, setUser] = useState();
	const [amount, setAmount] = useState();
	const [reference, setRefence] = useState();
	const [isSuccess, setIsSuccess] = useState(false);
	const { method, setMethod, setEnv, env } = useGeneralState();

	const query = qs.parse(window.location.search);
	const isLive = query.publicKey?.includes('XCLPUBK_LIVE');
	const isRedirect = query?.redirect ?? false;
	const customer = JSON.parse(query?.customer ?? '{}');
	const description = query?.description ?? '';

	if (!isLive) {
		if (!query?.publicKey) {
			query.publicKey = 'XCLPUBK_TEST-62a3d9d8933e7aafd507691c385f1852f91aaff1';
		}

		if (!query?.merchantId) {
			query.merchantId = 'BSWkPrkRJ'; // your merchant id
		}

		if (!query?.customer) {
			query.customer = JSON.stringify({
				name: 'Victor Umesiobi',
				email: 'victor.umesiobi@etranzactglobal.com',
			});
			query.reference = uuid();
		}

		if (!query?.amount) {
			query.amount = 300; // amount to be paid
		}

		if (!query?.currency) {
			query.alpha2CountryCode = 'GB'; //country code in uppercase
			query.currency = 'GBP';
		}
	}

	const customerName = customer?.name ?? '';
	const customerEmail = customer?.email ?? '';

	const keys = isLive
		? 'pk_live_HySrUa1uATDUSsDpT8zdRZRJ00pAFZp6ON'
		: 'pk_test_sJIuunk2QliIlIH35cSlX9ku00S65bZBWh';

	const { setUrl, setTransactionBaseUrl, setMonoUrl } = useGeneralState();

	const onMethodSelected = (method) => {
		setMethod(method);
		setIsSuccess(false);
	};
	const getMerchantDetails = async () => {
		setIsReady(false);

		//get the merchant id and token from query
		let token, url, transactionBaseUrl, monoUrl;
		if (isLive) {
			url = 'https://api.xcelapp.com/business-api/';
			transactionBaseUrl = 'https://api.xcelapp.com/transactions-service/';
			// monoUrl = "https://api.xcelapp.com/momo/";
			monoUrl = 'https://sandbox-api.xcelapp.com/momo/';

			setUrl(url);
			setTransactionBaseUrl(transactionBaseUrl);
			setMonoUrl(monoUrl);
			setEnv('production');
		} else {
			url = 'https://sandbox-api.xcelapp.com/business-api/';
			monoUrl = 'https://sandbox-api.xcelapp.com/momo/';
			transactionBaseUrl =
				'https://sandbox-api.xcelapp.com/transactions-service/';
			setTransactionBaseUrl(transactionBaseUrl);
			setUrl(url);
			setMonoUrl(monoUrl);
			setEnv('test');
		}

		setRefence(query?.reference ?? undefined);

		// Find merchant details
		try {
			const merchantDetail = await axios.get(
				`${url}merchant/details/${query.merchantId}`,
				{
					headers: {
						Authorization: query.publicKey,
					},
				}
			);

			const details = merchantDetail?.data?.data?.data;
			setStripeId(details?.stripe_id ?? undefined);
			setMerchantDetails(details);
			setPaymentMethods(details?.payment_options ?? []);
		} catch (e) {}

		try {
			const validation_response = await axios.get(
				`${url}merchant/pay/validate/${query.merchantId}`,
				{
					headers: {
						Authorization: query.publicKey,
					},
				}
			);
			token = validation_response.data.data.token;
		} catch (e) {}

		setAuthorisationToken(token, url);

		//TODO: Revisit all this

		//set the current country for the transaction
		const payer_country = getCountryByCode(query?.alpha2CountryCode);

		setCountry(payer_country);

		const price = query?.amount ?? 0;
		if (!price) {
			// show the error and exit
			console.log('No price');
			return;
		}
		setAmount(price);

		const payer_currency = payer_country.currencies.filter(
			(curr) => curr.code === query?.currency
		)[0];

		setCurrency(payer_currency);

		try {
			//TODO: Revisit this
			const merchant_response = await axios.get(
				`${url}merchant/pos/${query.merchantId}`
			);
			const merchant_data = merchant_response.data.data;
			setMerchant(merchant_data);
			setStep(3);
		} catch (e) {
			//show alert or warning
			console.log('request_error', e);
			return;
		}

		setIsReady(true);
		setMethod(PaymentMethod.PayWithXCEL);
	};

	const onPaymentSuccess = () => {
		setIsSuccess(true);
	};

	const onUserVerified = (userData) => {
		setUser(userData);
	};

	const isNotSuccess = !isSuccess;

	useEffect(() => {
		getMerchantDetails();
	}, []);

	return (
		<QueryClientProvider client={queryClient}>
			<div className=''>
				<div className='w-full bg-white h-[70px]'>
					<div className='logo'>
						<img
							src={
								!isEmpty(merchantDetails?.imageUrl)
									? merchantDetails?.imageUrl
									: './images/logo.svg'
							}
							alt=''
						/>

						<div
							onClick={() => {
								if (window && window?.parent) {
									const query = qs.parse(window.location.search);
									window?.parent.postMessage(
										{ name: 'cancelled' },
										query?.origin
									);
								}
							}}
							className='text-[#777777] text-sm font-light absolute right-6 cursor-pointer'>
							<BsX
								size={20}
								color='#000'
							/>
						</div>
					</div>
				</div>

				<div className=' w-full justify-center items-center flex'>
					<div className='md:p-5 p-0 md:w-auto w-full bg-[#F1F1F1]  min-h-[400px]'>
						{isRedirect && (
							<div className='modal flex md:flex-row flex-col gap-3  items-center justify-center'>
								<div className='w-full h-full justify-center items-center text-bold font-bold'>
									<div className='w-full flex justify-center items-center text-3xl'>
										Authentication successful
									</div>
									<div className=' mt-2 font-light w-full items-center justify-center flex text-2xl'>
										Click <b className='px-2'>"make payment"</b> to continue
									</div>
								</div>
							</div>
						)}

						{!isRedirect && (
							<div className='modal flex md:flex-row flex-col gap-3 justify-between w-full h-full'>
								<div
									className={`${
										isNotSuccess ? 'md:w-[69%]' : 'w-full'
									} h-full`}>
									{isNotSuccess && (
										<div className='bg-[#A4A4A4] w-full h-[50px] px-4 text-white justify-start flex items-center font-bold'>
											Pay With
										</div>
									)}

									<div className='w-full bg-white relative h-full'>
										<div
											className={`modal-body m-auto pt-4 flex flex-col flex-1 md:flex-row  ${
												!isNotSuccess ? 'justify-center items-center' : ''
											}`}>
											{isNotSuccess && (
												<div className='payment-methods md:flex-col flex-row gap-2 justify-start items-start '>
													<p className='pl-4 text-[#777777] mb-5'>
														Payment Options
													</p>

													<PaymentMethods
														onMethodSelected={onMethodSelected}
														selectedPaymentMethod={paymentMethods}
													/>
												</div>
											)}

											<div className='relative h-full'>
												{isSuccess ? (
													<div className='payment-page row-start-1 col-span-4 w-full'>
														<CSSTransition
															timeout={500}
															classNames='item'>
															<Success
																setStep={setStep}
																merchant={merchantDetails}
																country={country}
																customerName={customerName}
																customerEmail={customerEmail}
																user={user}
																reference={reference}
																amount={amount}
																currency={currency}
															/>
														</CSSTransition>
													</div>
												) : (
													<div className='w-full h-full relative'>
														{isReady ? (
															<div className=''>
																{method === PaymentMethod.PayWithXCEL && (
																	<XcelPay
																		merchant={merchant}
																		amount={amount}
																		country={country}
																		reference_id={reference}
																		setMethod={setMethod}
																		onPaymentSuccess={onPaymentSuccess}
																		onUserVerified={onUserVerified}
																		currency={currency}
																	/>
																)}

																{method === PaymentMethod.PayWithCard && (
																	<div
																		className={`${
																			method !== PaymentMethod.PayWithCard
																				? 'hidden'
																				: ''
																		} w-full h-full`}>
																		<StripeCardPayment
																			{...{
																				amount,
																				query,
																				onPaymentSuccess,
																				country,
																				currency,
																				customerEmail,
																				customerName,
																				description,
																				keys,
																				reference,
																				setUser,
																				stripeId,
																				user,
																				merchantId: query.merchantId,
																				merchantName: merchantDetails?.reg_name,
																			}}
																		/>
																	</div>
																)}
																{method === PaymentMethod.PayWithBankApp && (
																	<BankApp
																		merchant={merchant}
																		amount={amount}
																		country={country}
																		onPaymentSuccess={onPaymentSuccess}
																		setMethod={setMethod}
																		currency={currency}
																	/>
																)}

																{method ===
																	PaymentMethod.PayWithBankAccount && (
																	<BankTransfer
																		merchant={merchant}
																		amount={amount}
																		country={country}
																		onPaymentSuccess={onPaymentSuccess}
																		setMethod={setMethod}
																		currency={currency}
																	/>
																)}

																{/* {method ===
																	PaymentMethod.PayWithBankAccount && (
																	<BankAccount
																		pageStep={1}
																		country={country}
																		amount={amount}
																		onPaymentSuccess={onPaymentSuccess}
																		merchant={merchant}
																	/>
																)} */}

																{method ===
																	PaymentMethod.PayWithMobileMoney && (
																	<MobileMoney
																		country={country}
																		currency={currency}
																		reference={reference}
																		amount={amount}
																		merchantId={query.merchantId}
																		onPaymentSuccess={onPaymentSuccess}
																		customerEmail={customerEmail}
																		customerName={customerName}
																	/>
																)}

																{/* <USSD /> */}
																{method === PaymentMethod.PayWithUSSD && (
																	<USSD />
																)}
																{method === PaymentMethod.PayWithQRCode && (
																	<QrCode />
																)}
															</div>
														) : (
															<div className='payment-page row-start-1 col-span-4' />
														)}
													</div>
												)}
											</div>
										</div>
									</div>
								</div>

								{isNotSuccess && (
									<div className='md:w-[30%] w-full flex flex-col justify-between'>
										<PaymentSummary
											total={amount}
											currency={currency}
											vat={0.0}
											merchant={merchantDetails?.reg_name ?? ''}
										/>

										<div className='w-full flex items-end justify-end mt-3'>
											<img
												src='./images/etranzact.png'
												alt=''
												className=' h-10'
											/>
										</div>
									</div>
								)}
							</div>
						)}
					</div>
				</div>

				<ToastContainer position='top-right' />
			</div>
		</QueryClientProvider>
	);
}

export default App;
