/** @format */

import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import axios from '../lib/axios';
import { getCountryByCode } from '../lib/countries';
import { useGeneralState } from '../state/useGeneralState';

function BankTransfer({
	method,
	merchant,
	onPaymentSuccess,
	onUserVerified,
	amount,
	currency,
	setMethod,
	country,
	pageStep,
}) {
	const [visible, setVisible] = useState(false);
	const [account, setAccount] = useState();
	const [bank, setBank] = useState();
	const [lastFour, setLastFour] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const [accountError, setAccountError] = useState();

	const { url } = useGeneralState();

	const isUkBank = /(GB)/i.test(country?.alpha2Code);

	const { data: merchantBank } = useQuery(
		['merchant-bank', merchant, country?.alpha2Code],
		() => {
			return isUkBank
				? axios.get(`${url}merchant/wallets/${merchant?.merchant_id}`)
				: axios.get(`${url}merchant/banks/${merchant?.merchant_id}`);
		},
		{
			enabled: !!country?.alpha2Code && !!merchant?.merchant_id,
		}
	);

	useEffect(() => {
		(async () => {
			try {
				if (isUkBank) {
					const bank = merchantBank?.data?.data?.[0];
					setBank(bank);
				} else {
					const details = merchantBank?.data?.data?.[0];
					const account = await validateBankAccount(
						details?.account_no,
						details?.bank_code,
						'NGN'
					);

					setAccount(account);
					setBank(details);
				}
			} catch (e) {
				console.log(e, 'error validating bank');
				setAccountError(
					'An error occured retrieving bank details, please try again.'
				);
			}
		})();
	}, [merchantBank, isUkBank]);

	const validateBankPayment = async () => {
		try {
			setLoading(true);
			const country = getCountryByCode(merchant?.country_code);
			await axios.get(
				`${url}transactions/verify/${lastFour}/${parseFloat(amount).toFixed(
					2
				)}/${country?.alpha2Code}/${merchant?.merchant_id}/600`
			);
			onPaymentSuccess();
			//show success
		} catch (e) {
			console.log(e);
			setLoading(false);
			if (e.response && e.response.status === 400) {
				setError('Payment not found');
			} else {
				setError('Error completing request.');
			}
			//slears the error
			setTimeout(() => setError(''), 3000);
		}
	};

	const validateBankAccount = async (accountNo, bankCode, currencyCode) => {
		try {
			const response = await axios.get(
				`${url}psp/validate/account/${currencyCode}/${bankCode}/${accountNo}`
			);
			return response.data.data;
		} catch (e) {
			throw new Error(e);
		}
	};

	return (
		<>
			{account && bank && country.alpha2Code === 'NG' ? (
				<div className='payment-section m-auto mt-12 p-5'>
					<h3>Pay with Bank App</h3>
					<p className='text-gray-400'>
						Make payment to the account details below to complete your
						transaction and click on the button to confirm
					</p>
					<div className='my-7'>
						<table>
							<tr style={{ fontSize: 12 }}>
								<td className='flex gap-2 text-center'>
									<span>
										<img
											src='./images/account-circle.svg'
											alt=''
										/>
									</span>
									Acct. Name
								</td>
								<td style={{ fontSize: 12 }}>{account.response_message}</td>
							</tr>
							<tr>
								<td
									className='flex gap-2 text-center'
									style={{ fontSize: 12 }}>
									<span>
										<img
											src='./images/bank-account.svg'
											alt=''
										/>
									</span>
									Bank
								</td>
								<td style={{ fontSize: 12 }}>{bank?.bank_name}</td>
							</tr>
							<tr>
								<td
									style={{ fontSize: 12 }}
									className='flex gap-2 text-center'>
									<span>
										<img
											src='./images/dial-pad.svg'
											alt=''
										/>
									</span>
									Acct. No
								</td>
								<td style={{ fontSize: 12 }}>
									{account?.beneficiary_account}{' '}
								</td>
							</tr>
						</table>
					</div>
					<div className='my-8'>
						<button
							class='bg-[#0095FF] hover:bg-[#0095FFb6] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full'
							type='button'
							loading={loading}
							disabled={loading || accountError}
							onClick={() => validateBankPayment}>
							I have made this Payment
						</button>
					</div>
				</div>
			) : (
				<div className='payment-section m-auto mt-12 p-5'>
					<h3>Pay with Bank App</h3>
					<p className='text-gray-400'>
						Make payment to the account details below to complete your
						transaction and click on the button to confirm
					</p>
					<div className='w-full items-center justify-center flex'>
						<Spin />
					</div>
				</div>
			)}
		</>
	);
}

export default BankTransfer;
