import React from "react";
import BankAppOtherCountries from "./BankAppOtherCountries";
import BankAppUk from "./BankAppUk";

function BankApp(props) {
  const { country } = props;
  const isNGBank = /(NG)/i.test(country?.alpha2Code);
  return (
    <>
      {!isNGBank ? (
        <BankAppUk {...props} />
      ) : (
        <BankAppOtherCountries {...props} />
      )}
    </>
  );
}

export default BankApp;
