/** @format */

import { Spin } from 'antd';
import * as _ from 'lodash';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

import axiosInstance from '../../lib/axios';
import { useAuthState } from '../../state/useAuthState';
import { useGeneralState } from '../../state/useGeneralState';
import Card from '../Card';
import Header from '../Header';
import { PaymentMethod } from '../PaymentMethods';

const StripeForm = ({
	amount,
	currency,
	onPaymentSuccess,
	stripeId,
	merchantId,
	setNameGlobal,
	customerName,
	customerEmail,
	alpha2CountryCode,
	reference,
	description,
}) => {
	const { setClientSecret, clientSecret } = useAuthState();
	const { transactionBaseUrl, method } = useGeneralState();

	useEffect(() => {
		const initStripe = async () => {
			try {
				const result = await axiosInstance.post(
					`${transactionBaseUrl}stripe/payment-intent?amount=${amount}&currency=${currency.code}`,
					{
						merchant_id: merchantId,
						stripeId,
						reference: reference,
						customerName,
						customerEmail,
						paymentType: 'card',
						alpha2CountryCode: alpha2CountryCode,
						description,
					}
				);

				const data = result.data;

				if (data?.error?.message) {
					toast.warning(data?.error?.message?.raw?.message);
					return;
				}

				setClientSecret(data.client_secret);
			} catch (e) {
				if (method === PaymentMethod.PayWithCard) {
					toast.warning(
						e?.response?.data?.error?.message ?? 'Error initializing payment'
					);
				}
			}
		};

		initStripe();
	}, [method]);

	if (
		_.isEmpty(clientSecret) ||
		_.isNull(clientSecret) ||
		_.isUndefined(clientSecret)
	) {
		return (
			<div className='payment-section m-auto mt-12'>
				<Header title='Pay with Debit/ Credit Card' />

				<div className='w-full flex justify-center p-5'>
					<Spin style={{ alginSelf: 'center' }} />;
				</div>
			</div>
		);
	}

	return (
		<Card
			onPaymentSuccess={onPaymentSuccess}
			country={currency.code}
			stripeId={stripeId}
			setNameGlobal={setNameGlobal}
		/>
	);
};

export default StripeForm;
