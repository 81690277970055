import { Select } from "antd";
import React, { useState } from "react";

function USSD(pageStep) {
  const [isBankProcessed, setIsBankProcessed] = useState(true);
  return (
    <div className="payment-section m-auto mt-12 p-5">
      <h3>Pay with USSD Code</h3>
      <p className="text-gray-400">
        Enter your details below to complete this transaction
      </p>
      <div className="my-7 w-full">
        <div className="flex flex-col gap-3">
          <label className="font-bold text-[#031339]">
            Select the Bank account you are paying from
          </label>

          <Select
            onSelect={(e) => {}}
            className="select-before"
            placeholder="Select Bank"
          >
            <Select.Option>Bank</Select.Option>
          </Select>
        </div>
      </div>

      {isBankProcessed && (
        <div className="flex">
          <img src="" alt="" />

          <span>Dial {`*737*984*Amount*888#`}</span>
        </div>
      )}

      <div className="my-8">
        {isBankProcessed ? (
          <button
            class="bg-[#0095FF] hover:bg-[#0095FFb6] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
            type="button"
          >
            I have made this Payment
          </button>
        ) : (
          <button
            class="bg-[#0095FF] hover:bg-[#0095FFb6] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
            type="button"
          >
            Proceed
          </button>
        )}
      </div>
    </div>
  );
}
export default USSD;
