/** @format */

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import GhanaCard from './GhanaCard';
import StripeForm from './stripe-form/StripForm';

const StripeCardPayment = ({
	keys,
	stripeId,
	amount,
	currency,
	country,
	reference,
	customerEmail,
	query,
	customerName,
	onPaymentSuccess,
	description,
	setUser,
	user,
	merchantId,
	merchantName,
}) => {
	const isGhana = country?.alpha2Code === 'GH';

	if (isGhana) {
		return (
			<GhanaCard
				{...{
					keys,
					stripeId,
					amount,
					currency,
					country,
					reference,
					customerEmail,
					query,
					customerName,
					onPaymentSuccess,
					description,
					setUser,
					user,
					merchantId,
					merchantName,
				}}
			/>
		);
	}

	return (
		<Elements
			stripe={loadStripe(
				keys,
				stripeId
					? {
							stripeAccount: stripeId,
					  }
					: undefined
			)}>
			<StripeForm
				amount={amount}
				currency={currency}
				// country={country}
				reference={reference}
				customerEmail={customerEmail}
				customerName={customerName}
				alpha2CountryCode={query.alpha2CountryCode}
				onPaymentSuccess={onPaymentSuccess}
				description={description}
				stripeId={stripeId}
				setNameGlobal={(e) =>
					setUser({
						...user,
						first_name: e,
						last_name: '',
					})
				}
				merchantId={query.merchantId}
			/>
		</Elements>
	);
};

export default StripeCardPayment;
