import { combine } from "zustand/middleware";
import { persist } from ".";
import create from "zustand";

const defaultState = {
  clientSecret: "",
  stripePromise: null,
};

export const useAuthState = create(
  persist(
    {
      key: "auth",
    },
    combine(defaultState, (set) => ({
      setClientSecret: (clientSecret) =>
        set((state) => ({
          ...state,
          clientSecret,
        })),
      setStripePromise: (stripePromise) =>
        set((state) => ({
          ...state,
          stripePromise,
        })),
      reset: () => set(() => defaultState),
    }))
  )
);
