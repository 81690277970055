/** @format */

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import axios from '../lib/axios';
import { getCountryByCode } from '../lib/countries';
import { getSchemeCode } from '../lib/functions';
import { useGeneralState } from '../state/useGeneralState';
import Header from './Header';

function XcelPay({
	merchant,
	amount,
	setMethod,
	onPaymentSuccess,
	country,
	currency,
	reference_id,
	onUserVerified,
}) {
	const [pageStep, setPageStep] = useState(1);
	const { url } = useGeneralState();
	const [isWalletverified, setIsWalletVerifed] = useState(false);
	const [countryCode, setCountryCode] = useState(country.callingCodes[0]);
	const [mobile, setMobile] = useState();
	const [loading, setLoading] = useState(false);
	const [visible, setVisible] = useState(false);
	// const [reference, setReference] = useState();
	const [account, setAccount] = useState();

	const onChange = (e) => {
		setAccount(null);
		setMobile(e.target.value);
	};

	const verificationRequest = async (paymentReference) => {
		setLoading(true);
		try {
			await axios.get(
				`${url}pos/transaction/verify/${merchant.merchant_id}/${paymentReference}`
			);

			onPaymentSuccess();
			setLoading(false);
		} catch (e) {
			setLoading(false);
			if (e && e.response && e.response.status === 400) {
				if (e.response.data && e.response.data.errors === 'Not yet paid') {
					//display error message
					toast.error('Not yet paid');
				}
			}
		}
	};

	console.log(account, 'account');

	const verifyWallet = async (e) => {
		e.preventDefault();
		e.stopPropagation();

		if (!countryCode || !mobile) {
			return;
		}

		try {
			const schemeCode = getSchemeCode(countryCode);
			const wallet = countryCode + mobile;
			setLoading(true);

			if (account) {
				const user_country = getCountryByCode(account.address.country);
				if (!user_country) {
					// show a not support country error
					return;
				}

				// reference_id = generateReference(merchant, wallet);
				const payer_country_code = schemeCode ?? account.scheme_code;
				//set notification to the mobile device for that number

				await axios.post(`${url}pos/payment`, {
					merchant_id: merchant.merchant_id,
					payer_wallet_no: wallet,
					pos_wallet_no: merchant.account.accountNumber,
					pos_scheme_code: merchant.scheme_code,
					amount,
					description: 'XCEL WEB payment',
					international: merchant.country_code !== payer_country_code,
					merchant_country_code: merchant.country_code,
					payer_country_code,
					merchant_currency: currency.code,
					//Ther is always a currency entry select the first entry as default
					payer_currency: user_country.currencies[0].code,
					trans_type: 'XCelPOS',
					product_id: 'XCelPOS',
					reference_id,
				});

				setVisible(true);
				setLoading(false);
			} else {
				const response = await axios.get(
					`${url}source/details/${countryCode}${mobile}/${schemeCode}`
				);
				const data = response.data.data;
				onUserVerified(data);
				setAccount(data);

				setLoading(false);
			}
		} catch (e) {
			//todo display error retrieving products
			setLoading(false);
		}
		// https://sandbox-api.etranzactglobal.com/source/details/2348105478821/234004
		//props.setStep(4)
	};

	if (pageStep === 1) {
		return (
			<div className='payment-section m-auto mt-12'>
				<Header title='Pay with XCel' />

				<div className='p-5 h-full w-full relative'>
					<p className='text-gray-400'>
						Please enter your registered XCel phone number
					</p>
					<div className='my-7'>
						<label
							className='block text-gray-700 text-sm font-bold mb-2'
							htmlFor='username'>
							XCel Phone Number
						</label>
						<div className='flex flex-wrap -mx-3 mb-2'>
							<div class='w-1/4 px-3 mb-6 md:mb-0'>
								<select
									className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
									name='banks'
									id='banks'>
									<option value={country.callingCodes[0]}>
										+{country.callingCodes[0]}
									</option>
								</select>
							</div>
							<div class='w-3/4 px-3 mb-6 md:mb-0'>
								<input
									className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
									id='username'
									type='text'
									placeholder='Phone Number'
									onChange={onChange}
								/>
							</div>
						</div>
						{account && (
							<p className='text-[#0095FF] text-xs italic float-right flex pt-2 mb-3 -top-2'>
								{account.first_name + ' ' + account.last_name}
								<span className='ml-2 text-center mt-[1.2px]'>
									<img
										src='./images/green-check.svg'
										alt=''
									/>
								</span>
							</p>
						)}
					</div>
					<div className='my-8 w-full'>
						{account && (
							<button
								className='bg-[#0095FF] hover:bg-[#0095FFb6] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full'
								type='button'
								onClick={(e) => {
									e.preventDefault();
									verifyWallet();
									setPageStep(2);
								}}>
								Proceed
							</button>
						)}
						{!account && (
							<button
								className='bg-[#0095FF] hover:bg-[#0095FFb6] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full'
								type='button'
								onClick={verifyWallet}
								loading={loading}>
								Verify Wallet
							</button>
						)}
					</div>
				</div>
			</div>
		);
	} else if (pageStep === 2) {
		return (
			<div className='payment-section m-auto mt-12 p-5'>
				<Header title='Pay with XCel' />

				<div className='p-5 w-full'>
					{visible ? (
						<>
							<p className='text-gray-400'>
								If payment has been made, click the button below to proceed.
							</p>

							<div className='my-8'>
								<button
									className='bg-[#0095FF] hover:bg-[#0095FFb6] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full'
									type='button'
									loading={loading}
									onClick={() => verificationRequest(reference_id)}>
									I've made this payment
								</button>
							</div>
						</>
					) : (
						<>
							<p className='text-gray-400'>
								Proceed to make payment if this is your wallet
							</p>

							{account && (
								<div className='font-bold'>
									{account.first_name + ' ' + account.last_name}
								</div>
							)}

							<div className='my-8'>
								<button
									className='bg-[#0095FF] hover:bg-[#0095FFb6] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full'
									type='button'
									loading={loading}
									onClick={verifyWallet}>
									Proceed
								</button>
							</div>
						</>
					)}
				</div>
			</div>
		);
	} else {
		return null;
	}
}

export default XcelPay;
