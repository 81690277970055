import { Select, Spin, Typography } from "antd";
import lodash from "lodash";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useCountdown } from "usehooks-ts";
import axios from "../lib/axios";
import { getCountryByCode } from "../lib/countries";
import { useGeneralState } from "../state/useGeneralState";

const { Title, Text } = Typography;

function BankAppOtherCountries({
  method,
  merchant,
  onPaymentSuccess,
  onUserVerified,
  amount,
  currency,
  setMethod,
  country,
  pageStep,
}) {
  const [lastFour, setLastFour] = useState("");
  const [loading] = useState(false);
  const [countdown, setCountdown] = useState(false);
  const [userName, setUserName] = useState("");
  const [bankType, setBankType] = useState();
  const [isVerifying, setIsVerifying] = useState(false);

  const { data: bankQuery } = useQuery(
    ["bank-list", country?.alpha2Code],
    () => {
      return axios.get(
        `${transactionBaseUrl}banks?isoCode=${country?.alpha2Code}`
      );
    }
  );

  const bankList = bankQuery?.data?.data ?? [];

  const [count, { startCountdown, stopCountdown, resetCountdown }] =
    useCountdown({
      countStart: 60 * 30,
      intervalMs: 1000,
    });

  useEffect(() => {
    if (count <= 0) {
      toast.error(
        "Payment verification failed. Support team would manually verify your payment."
      );
    }
  }, [count]);

  const { transactionBaseUrl } = useGeneralState();

  const { data: merchantBank, isLoading: isMerchantBankLoading } = useQuery(
    ["merchant-bank", merchant, country?.alpha2Code, bankType],
    async () => {
      try {
        const response = await axios.post(
          `${transactionBaseUrl}transactions/payment-account`,
          {
            merchant_id: merchant?.merchant_id,
            currency_code: currency?.code,
            bankName: bankType,
          }
        );

        return response?.data;
      } catch (e) {
        toast.warning(e?.response?.data?.message);
      }
    },
    {
      enabled: !lodash.isEmpty(userName),
      retry: true,
    }
  );

  const verifyAccount = useMutation(async () => {
    try {
      const response = await axios.post(
        `${transactionBaseUrl}banks/name-enquiry`,
        {
          accountNumber: lastFour,
          isoCode: country?.alpha2Code,
          bankName: bankType,
        }
      );

      setUserName(response?.data?.data?.AccountName);
      setIsVerifying(false);

      return response?.data;
    } catch (e) {
      console.log(e);
      toast.warning(e?.response?.data?.message);
      setIsVerifying(false);
    }
  });

  const account = merchantBank?.data;

  const validateBankPayment = async () => {
    setCountdown(true);
    startCountdown();
  };

  const minutes = Math.floor(count / 60);
  const seconds = count % 60;

  const checkValidatePayment = async () => {
    try {
      const country = getCountryByCode(merchant.country_code);
      await axios.get(
        `${transactionBaseUrl}transactions/confirm-transaction?src_amount_paid=${amount}&src_account=${lastFour}&des_account=${
          account?.account_number
        }&isoCode=${
          country?.alpha2Code
        }&date=${new Date().toISOString()}&ref_no=${account?.ref_no}`
      );
      onPaymentSuccess();
    } catch (e) {
      toast.error("We could not verify your payment. Try again later.");
      stopCountdown();
      setCountdown(false);
    }
  };

  useEffect(() => {
    if (countdown && count > 0) {
      checkValidatePayment();
    }
  }, [Math.floor(count / 60), countdown]);

  return (
    <>
      <div className="payment-section m-auto mt-12 p-5">
        <h3>Pay with Bank App</h3>
        <p className="text-gray-400">
          Make payment to the account details below to complete your transaction
          and click on the button to confirm
        </p>
        <div className={`my-7 py-4  rounded-md`}>
          <div className="w-full flex flex-col gap-4">
            <div className="flex flex-col gap-3">
              <Text className="font-bold text-[#031339]">
                Select the Bank account you are paying from
              </Text>

              <Select
                onSelect={(e) => {
                  setUserName("");
                  setBankType(e);
                }}
                defaultValue={bankList?.[0]}
                className="select-before"
                placeholder="Select Bank"
              >
                {bankList.map((i) => {
                  return <Select.Option value={i}>{i}</Select.Option>;
                })}
              </Select>
            </div>

            <div className="flex flex-col gap-3">
              <div className="flex flex-row justify-between items-center">
                <Text className="font-bold text-[#031339]">
                  Your Account No.
                </Text>

                {userName && (
                  <div className="flex justify-end items-center gap-2 text-xs text-[#0095FF] font-bold">
                    {userName}
                    <img src="./images/success.svg" alt="" />
                  </div>
                )}
              </div>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="accountnumber"
                type="text"
                placeholder="Bank account"
                onChange={(e) => {
                  setLastFour(e.target.value);
                  setUserName("");
                }}
              />
            </div>

            {isMerchantBankLoading && <Spin />}

            {bankType && lastFour && !lodash.isEmpty(userName) && (
              <div className="flex flex-col gap-3 bg-gray-100 rounded-md">
                <table>
                  <tr>
                    <td className="flex gap-2 text-center items-center">
                      <span>
                        <img src="./images/account-circle.svg" alt="" />
                      </span>
                      Acct. Name
                    </td>
                    <td>{account?.merchant_name ?? ""}</td>
                  </tr>
                  <tr>
                    <td className="flex gap-2 text-center items-center">
                      <span>
                        <img src="./images/bank-account.svg" alt="" />
                      </span>
                      Bank
                    </td>
                    <td>{account?.bank_name ?? " "}</td>
                  </tr>
                  <tr>
                    <td className="flex gap-2 text-center items-center">
                      <span>
                        <img src="./images/dial-pad.svg" alt="" />
                      </span>
                      Acct. No
                    </td>
                    <td>{account?.account_number ?? ""} </td>
                  </tr>

                  <tr>
                    <td className="flex gap-2 text-center items-center">
                      <span>
                        <img src="./images/dial-pad.svg" alt="" />
                      </span>
                      Ref. No | Description
                    </td>
                    <td
                      className="max-w-[50%] flex flex-row gap-2 cursor-pointer"
                      onClick={() => {
                        navigator.clipboard.writeText(account?.ref_no ?? "");
                        toast.success("Copied to clipboard");
                      }}
                    >
                      <span>{account?.ref_no ?? ""} </span>

                      <img
                        src="./images/copy.svg"
                        alt=""
                        className="w-[15px] h-[15px]"
                      />
                    </td>
                  </tr>
                </table>
              </div>
            )}
          </div>
        </div>
        <div>
          <button
            class="bg-[#0095FF] hover:bg-[#0095FFb6] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
            type="button"
            loading={loading || isVerifying}
            disabled={countdown}
            onClick={async () => {
              if (lodash.isEmpty(userName)) {
                setIsVerifying(true);
                await verifyAccount.mutateAsync();
              } else {
                toast.success(
                  "Please wait while your payment is being verified",
                  {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  }
                );
                validateBankPayment();
              }
            }}
          >
            <div className="flex justify-center items-center gap-2">
              {(countdown || isVerifying) && <Spin />}
              <span>
                {lodash.isEmpty(userName)
                  ? "Verify your account"
                  : countdown
                  ? "Complete Transactions"
                  : "I have made this payment"}
              </span>

              {countdown && (
                <span>
                  {count < 60
                    ? `${seconds}s left`
                    : `${minutes}:${seconds < 10 ? "0" : ""}${seconds} mins`}
                </span>
              )}
            </div>
          </button>
        </div>
      </div>
    </>
  );
}

export default BankAppOtherCountries;
