import { React, useState } from "react";
import { usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import { toast } from "react-toastify";
// import CreditCardInput from "react-credit-card-input";
import { Spin } from "antd";
import Header from "./Header";

function PlainCard({ readOnly = { readOnly }, handleSubmitProps }) {
  const [processing, setProcessing] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const { getCardImageProps } = usePaymentInputs();
  const [cvc, setCvc] = useState("");

  const handleSubmit = async (event) => {
    try {
      await handleSubmitProps({
        number: cardNumber,
        cvc,
        exp_month: expiry,
        exp_year: expiry,
      });

      setProcessing(false);
    } catch (error) {
      toast.warning(
        error?.response?.data?.error?.message ??
          error?.message?.raw?.message ??
          error?.message
      );
    }
  };

  const cardComponent = () => {
    return (
      <>
        <div className="my-7">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            for="username"
          >
            Card Number
          </label>
          <div className="">
            <svg
              {...getCardImageProps({ images })}
              className="absolute mt-4 ml-1"
            />
            <input
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline pl-8"
              type="text"
              placeholder="Card Number"
              id="card-number"
              readOnly
            />
          </div>
        </div>
        <div className="my-7  ">
          <div class="flex flex-wrap -mx-3 mb-2">
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="expiry-date"
              >
                Expiry Month
              </label>
              <input
                class="appearance-none block w-full shadow text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
                placeholder="MM"
                id="expiry-month"
                readOnly
              />
            </div>

            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="expiry-date"
              >
                Expiry Year
              </label>
              <input
                class="appearance-none block w-full shadow text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
                placeholder="YY"
                id="expiry-year"
                readOnly
              />
            </div>
          </div>
        </div>

        <div className="my-2">
          <div className="flex flex-wrap -mx-3 mb-2">
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="cvc"
              >
                CVC
              </label>
              <input
                class="appearance-none block w-full shadow text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
                placeholder="cvc"
                id="cvc"
                readOnly
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="payment-section m-auto mt-12">
      <Header title="Pay with Debit/ Credit Card" />

      <div className="p-5">
        <p className="text-gray-400">
          Please enter your card details to make payment
        </p>
        <div className="my-7">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            for="nameOnCard"
          >
            Card Holder's Name
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="nameOnCard"
            readOnly
            type="text"
            placeholder="Card Holder's Name"
            // onChange={(e) => {
            //   setName(e.target.value);
            //   setNameGlobal(e.target.value);
            // }}
          />
        </div>

        {cardComponent()}

        <div className="my-8">
          <button
            class="bg-[#0095FF] hover:bg-[#0095FFb6] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
            type="button"
            onClick={handleSubmit}
            loading={processing}
            disabled={processing}
          >
            Proceed {processing && <Spin />}
          </button>
        </div>
      </div>
    </div>
  );
}

export default PlainCard;
