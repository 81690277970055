import { useElements, useStripe } from "@stripe/react-stripe-js";
import CountryDropdown from "country-dropdown-with-flags-for-react";
import { React, useEffect, useState } from "react";
import { usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import { toast } from "react-toastify";
// import CreditCardInput from "react-credit-card-input";
import { Spin } from "antd";
import axiosInstance from "../lib/axios";
import { useAuthState } from "../state/useAuthState";
import { useGeneralState } from "../state/useGeneralState";
import Header from "./Header";

function Card({ onPaymentSuccess, country, stripeId, setNameGlobal }) {
  const { transactionBaseUrl } = useGeneralState();

  const [pageStep, setPageStep] = useState(1);
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState();
  const { clientSecret } = useAuthState();
  const [cardNumber, setCardNumber] = useState("");
  const [name, setName] = useState(""); // for non NGN countries [name, setName] = useState("");
  const [expiry, setExpiry] = useState("");
  const {
    meta,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    getCardImageProps,
  } = usePaymentInputs();
  const [cvc, setCvc] = useState("");

  useEffect(() => {
    fetch("https://ipapi.co/json/")
      .then((res) => res.json())
      .then((response) => {
        console.log("Country is : ", response);
      })
      .catch((data, status) => {
        console.log("Request failed:", data);
      });
  }, []);

  const handleSubmit = async (event) => {
    try {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      setProcessing(true);

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      //TODO: use tokenized card instead

      const exp_month = Number(expiry.split("/")[0].trim());
      const exp_year = Number(expiry.split("/")[1].trim().replace(/\s+/g, ""));

      const result = await axiosInstance.post(
        `${transactionBaseUrl}stripe/card-token`,
        {
          number: cardNumber,
          cvc,
          exp_month,
          exp_year,
          stripeId,
        }
      );

      if (result?.error?.message) {
        toast.warning(
          result?.error?.message?.raw?.message ?? result?.error?.message
        );
        setProcessing(false);
        return;
      }

      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: { token: result?.data?.cardTokenized?.id },
          },
        }
      );

      if (error) {
        console.log(error);
        toast.error(error.message);
        setProcessing(false);

        return;
      }
      setProcessing(false);
      onPaymentSuccess();
    } catch (error) {
      toast.warning(
        error?.response?.data?.error?.message ??
          error?.message?.raw?.message ??
          error?.message
      );
      setProcessing(false);
    }
  };

  const cardComponent = () => {
    return (
      <>
        <div className="my-7">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            for="username"
          >
            Card Number
          </label>
          <div className="">
            <svg
              {...getCardImageProps({ images })}
              className="absolute mt-4 ml-1"
            />
            <input
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline pl-8"
              id="username"
              type="text"
              placeholder="Card Number"
              {...getCardNumberProps({
                onChange: (e) => setCardNumber(e.target.value),
              })}
            />
          </div>
        </div>
        <div className="my-7  ">
          <div class="flex flex-wrap -mx-3 mb-2">
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="expiry-date"
              >
                Expiry Date
              </label>
              <input
                class="appearance-none block w-full shadow text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="expiry-date"
                type="text"
                placeholder="MM/YY"
                {...getExpiryDateProps({
                  onChange: (e) => setExpiry(e.target.value),
                })}
              />
            </div>
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="cvc"
              >
                CVC
              </label>
              <input
                class="appearance-none block w-full shadow text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="cvc"
                type="text"
                {...getCVCProps({ onChange: (e) => setCvc(e.target.value) })}
                placeholder="cvc"
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  if (country === "NGN" && pageStep == 1) {
    return (
      <div className="payment-section m-auto mt-12">
        <Header title="Pay with Debit/ Credit Card" />

        <div className="p-5 w-full">
          <p className="text-gray-400">
            Please enter your card details to make payment
          </p>

          {cardComponent()}

          <div className="my-8">
            <button
              class="bg-[#0095FF] hover:bg-[#0095FFb6] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
              type="button"
              onClick={handleSubmit}
              loading={processing}
              disabled={!stripe || processing}
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    );
  } else if (country !== "NGN" && pageStep == 1) {
    return (
      <div className="payment-section m-auto mt-12">
        <Header title="Pay with Debit/ Credit Card" />

        <div className="p-5 w-full">
          <p className="text-gray-400">
            Please enter your card details to make payment
          </p>
          <div className="my-7">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              for="username"
            >
              Card Holder's Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="username"
              type="text"
              placeholder="Card Holder's Name"
              onChange={(e) => {
                setName(e.target.value);
                setNameGlobal(e.target.value);
              }}
            />
          </div>

          {cardComponent()}

          <div className="my-7">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              for="username"
            >
              Card Holder's Address
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="username"
              type="text"
              placeholder="Card Holder's Address"
            />
          </div>
          <div className="my-7  ">
            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="town"
                >
                  Town/City
                </label>
                <input
                  class="appearance-none block w-full shadow text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="town"
                  type="text"
                  placeholder="Town/City"
                />
              </div>
              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="state"
                >
                  State/ Region/ County
                </label>
                <input
                  class="appearance-none block w-full shadow text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="state"
                  type="text"
                  placeholder="State/ Region/ County"
                />
              </div>
            </div>
          </div>
          <div className="my-7  ">
            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="zip-code"
                >
                  Postal/ Zip Code
                </label>
                <input
                  class="appearance-none block w-full shadow text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="zip-code"
                  type="text"
                  placeholder="Postal/ Zip Code"
                />
              </div>

              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0 ">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="country"
                >
                  Country
                </label>
                <div class="appearance-none block w-full shadow text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                  <CountryDropdown
                    id="UNIQUE_ID"
                    className="w-full h-full outline-none border-none"
                    preferredCountries={["gb", "us"]}
                    value=""
                    handleChange={(e) => console.log(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="my-8">
            <button
              class="bg-[#0095FF] hover:bg-[#0095FFb6] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
              type="button"
              onClick={handleSubmit}
              loading={processing}
              disabled={!stripe || processing}
            >
              Proceed {processing && <Spin />}
            </button>
          </div>
        </div>
      </div>
    );
  } else if ((pageStep = 2)) {
    return (
      <div className="payment-section m-auto mt-12">
        <Header title="Pay with Debit/ Credit Card" />

        <div className="p-5 w-full">
          <p className="text-gray-400">
            Please enter the OTP sent to your XCel app
          </p>
          <div className="my-7">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              for="username"
            >
              Enter OTP
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="username"
              type="password"
              placeholder="******"
            />
          </div>
          <div className="my-8">
            <button
              class="bg-[#0095FF] hover:bg-[#0095FFb6] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
              type="button"
              onClick={handleSubmit}
              loading={processing}
              disabled={!stripe || processing}
            >
              Make Payment
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Card;
