import axios from "axios";
import join from "url-join";

const axiosInstance = axios.create({
    baseURL: "",
    timeout: 20000,
});

export function setAuthorisationToken(token, baseURL) {
    // axiosInstance.interceptors.request.use(function (config) {
    //   // Concatenate base path if not an absolute URL
    //   config.url = join(baseURL, config.url);

    //   return config;
    // });
    //setup interceptors for 401 errors
    axiosInstance.interceptors.response.use(
        function (response) {
            // Do something with response data
            return response;
        },
        function (error) {
            if (error.response && error.response.status === 401) {
                error.message = "Unauthorized";
            }

            // Do something with response error
            return Promise.reject(error);
        }
    );

    if (token) {
        axiosInstance.defaults.headers.common["Authorization"] = token;
    } else {
        delete axiosInstance.defaults.headers.common["Authorization"];
    }
}

export default axiosInstance;
