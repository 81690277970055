import React from "react";

const Header = ({ title }) => {
  return (
    <div className="">
      <h1 className="font-bold uppercase p-5">{title}</h1>
      <div className="w-full h-[1px] bg-[#ECECEC]" />
    </div>
  );
};
export default Header;
